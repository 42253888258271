import * as React from "react"
import Header from "../components/layout/Header"
import Modal from "../components/layout/Modal"
import Nav from "../components/layout/Nav"
import * as ProcessOut from "../util/ProcessOut"
import NotificationCenter from "../features/NotificationBar/NotificationCenter"
import ErrorBoundary from "../features/ErrorBoundary"
import SandboxIndicator from "../features/SandboxIndicator/index"
import type { $RouterLocation } from "^/util/Types"

type Props = {
  location: $RouterLocation
  children?: React.ReactNode
  params: {
    project: string
  }
}

class Layout extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    ProcessOut.config.ProjectId = this.props.params.project
  }

  render() {
    return (
      <>
        <Modal />
        <div className="colorful main-wrapper">
          <Header isTransparent={false} />
          <div className="row main-content shadowed rounded-top">
            <NotificationCenter />
            <div className="large-12 columns">
              <SandboxIndicator />
            </div>
            <Nav location={this.props.location}>
              <ErrorBoundary>{this.props.children}</ErrorBoundary>
            </Nav>
          </div>
        </div>
      </>
    )
  }
}

export default Layout
