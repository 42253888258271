import React from "react"
import { connect } from "react-redux"
import type { $Dispatcher } from "../../../../../util/Types"
import { CLOSE_CHART_POPUP } from "./consts"
import { $LaunchDarklyState } from "../../../../../vNext/LaunchDarkly/LaunchDarklyReducer"
import type { $ProjectsState } from "^/stores/Reducers/ProjectsReducer"

type Props = {
  onFilter: (() => void) | null | undefined
  chartId: string | null | undefined
  canUpdateAnalyticsFilter?: boolean
  correspondingChartId: string
  // chat that renders this popup
  correspondingFilter: string
  launchDarkly: $LaunchDarklyState
  projectsDetails: $ProjectsState
} & $Dispatcher

class ChartPopup extends React.Component<Props> {
  closePopup = (event: Event) => {
    const { dispatch } = this.props
    event.preventDefault
    dispatch({
      type: CLOSE_CHART_POPUP,
    })
  }

  render() {
    const {
      onFilter,
      chartId,
      correspondingChartId,
      correspondingFilter,
      canUpdateAnalyticsFilter,
      launchDarkly,
      projectsDetails,
    } = this.props
    const projectId = projectsDetails.current_project_id
    const openInTransactionsUrl = launchDarkly.flags["new-dash-transactions-page"]
      ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${projectId}/transactions?legacy_filter=${correspondingFilter}`
      : `/projects/${projectId}/transactions?filter=${correspondingFilter}`
    if (chartId !== correspondingChartId) return null
    return (
      <div
        className="row"
        style={{
          width: "100%",
          height: "200px",
          position: "relative",
          top: "-200px",
          paddingTop: "50px",
        }}
      >
        {canUpdateAnalyticsFilter ? (
          <div>
            {" "}
            <div className="large-6 columns text-right">
              <a
                data-auto-tracking={true}
                className="round main button popup-button small-font"
                onClick={onFilter}
              >
                Update analytics filter
              </a>
            </div>
            <div className="large-6 columns text-left">
              <a
                data-auto-tracking={true}
                className="round main button popup-button small-font"
                href={openInTransactionsUrl}
                target="_blank"
                onClick={this.closePopup}
                rel="noreferrer"
              >
                List transactions
              </a>
            </div>
          </div>
        ) : (
          <div className="large-12 columns text-center">
            <a
              data-auto-tracking={true}
              className="round main button popup-button small-font"
              href={openInTransactionsUrl}
              target="_blank"
              onClick={this.closePopup}
              rel="noreferrer"
            >
              List corresponding transactions
            </a>
          </div>
        )}
      </div>
    )
  }
}

export default connect(store => ({
  ...store.analytics_v2.popup,
  launchDarkly: store.launchDarkly,
  projectsDetails: store.projects,
}))(ChartPopup)
