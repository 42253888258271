import React from "react"
import { render } from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { Router } from "react-router"
import { IntlProvider } from "react-intl"
import { Provider } from "react-redux"
import { Routes } from "./util/Router"
import { history, store } from "./stores/Store"
import "../scss/sero.scss"
import "../scss/app.scss"
import "../VendorStyles/styles.scss"
import ErrorBoundary from "./features/ErrorBoundary/index"
import moment from "moment"
import initDatadog from "^/vNext/Datadog/initDatadog"
import { init as initSegment } from "^/vNext/Segment"
import { initAutoTracking } from "^/vNext/tracking"

const appElement = document.getElementById("app")
initDatadog(process.env.TARGET_ENV)
initSegment(process.env.TARGET_ENV)
initAutoTracking()

class App extends React.Component {
  locale = navigator ? navigator.language || "en-US" : "en-US"

  async componentDidMount() {
    moment.locale(this.locale)

    //@ts-ignore - Set up ProcessOutConfig
    window.ProcessOutConfig = {
      company: "ProcessOut",
      features: ["*"],
    }

    // Add Jimo
    const s = document.createElement("script")
    window.jimo = []
    s.type = "text/javascript"
    s.async = true
    s.src = "https://undercity.usejimo.com/jimo-invader.js"
    window["JIMO_PROJECT_ID"] = "c6e915b6-4da2-4986-b9f1-14c0ebab2c00"
    document.getElementsByTagName("head")[0].appendChild(s)
  }

  render() {
    return (
      <Provider store={store}>
        <IntlProvider locale={this.locale}>
          <ErrorBoundary>
            <BrowserRouter>
              <Router history={history}>{Routes}</Router>
            </BrowserRouter>
          </ErrorBoundary>
        </IntlProvider>
      </Provider>
    )
  }
}

render(<App {...appElement.dataset} />, appElement)

if (module.hot) {
  module.hot.accept()
}
