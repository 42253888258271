import React from "react"
import { FormattedNumber } from "react-intl"
import { Link } from "react-router"
import type { $Dispatcher } from "../../../util/Types"
import Error from "../../../components/Error"
import { ANALYTICS_FILTER_CHANGE } from "^/Actions/AnalyticsActions"
import { connect } from "react-redux"
import { $LaunchDarklyState } from "../../../vNext/LaunchDarkly/LaunchDarklyReducer"

type Props = {
  value: number
  unit: string
  currentProject: {
    project?: {
      id: string
    }
  }
  correspondingFilter: string | null | undefined
  error?: boolean | null | undefined
  launchDarkly: $LaunchDarklyState
} & $Dispatcher

export class SingleValue extends React.Component {
  props: Props

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent("resize-dashboard"))
  }

  filter = (event: Event) => {
    event.preventDefault()
    this.props.dispatch({
      type: ANALYTICS_FILTER_CHANGE,
      payload: {
        filter: this.props.correspondingFilter,
      },
    })
  }

  render() {
    const { unit, currentProject, correspondingFilter, launchDarkly } = this.props
    if (!currentProject.project) return null
    let content

    switch (unit) {
      case "percentage": {
        content = (
          <FormattedNumber value={this.props.value} style="percent" minimumFractionDigits={2} />
        )
        break
      }

      case "amount": {
        content = (
          <FormattedNumber
            value={this.props.value}
            currency={currentProject.project.default_currency}
            style="currency"
            currencyDisplay="symbol"
          />
        )
        break
      }

      default: {
        content = <FormattedNumber value={this.props.value} maximumFractionDigits={2} />
      }
    }

    let error

    if (this.props.error) {
      content = null
      error = <Error text="An error occured while loading this chart." />
    }

    return (
      <div className="row">
        <div className="large-12 columns text-center">
          {correspondingFilter ? (
            <div className="large-value">
              {launchDarkly.flags["new-dash-transactions-page"] ? (
                <a
                  style={{ whiteSpace: "nowrap" }}
                  target="_self"
                  className="single-value-number"
                  data-testid="new-dashboard-link"
                  href={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${
                    currentProject.project.id
                  }/transactions?legacy_filter=${encodeURIComponent(correspondingFilter)}`}
                >
                  {content}
                  {error}
                </a>
              ) : (
                <Link
                  data-testid="old-dashboard-link"
                  class="single-value-number"
                  to={`/projects/${
                    currentProject.project.id
                  }/transactions?filter=${encodeURIComponent(correspondingFilter)}`}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {content}
                  {error}
                </Link>
              )}
            </div>
          ) : (
            <div>
              <div
                className="large-value"
                style={{
                  whiteSpace: "nowrap",
                }}
                data-testid="single-value-content"
              >
                {content}
              </div>
              <span
                className="small-text"
                style={{
                  position: "relative",
                  top: "-3em",
                }}
              >
                {error}
              </span>
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default connect(store => ({
  currentProject: store.currentProject,
  launchDarkly: store.launchDarkly,
}))(SingleValue)
