import React from "react"
import { connect } from "react-redux"
import qs from "qs"
import MenuButton from "./MenuButton"
import SubButton from "./SubButton"
import * as ProcessOut from "../../util/ProcessOut"
import { hasFeature } from "../../features/FeatureFlags/FeatureFlags"
import "./SideMenuStyle.scss"
import { withRouter } from "react-router"
import SandboxSwitch from "../../features/SandboxSwitch"
import UserTag from "../../features/UserTag/UserTag"

export class SideMenu extends React.Component {
  constructor() {
    super()
    this.state = {
      sandbox: ProcessOut.config.sandbox,
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {
      currentProject,
      boards,
      analyticsParams,
      location,
      params,
      user,
      sandbox,
      launchDarkly,
    } = this.props
    const oldFeatureFlags = currentProject?.project?.feature_flags
    // we get the current applied filter to generate its query string to insert it into each board's link
    const query = {
      filter: analyticsParams.filter,
    }
    const queryString = qs.stringify(query)
    const analytics = currentProject.project && (
      <MenuButton
        location={location}
        route="boards"
        name="Analytics"
        richLabel={
          <div
            style={{
              display: "inline-block",
            }}
          >
            <div
              style={{
                display: "inline-block",
              }}
            >
              Analytics
            </div>
          </div>
        }
        to={`/projects/${params.project}/boards?${queryString}`}
      >
        {(new RegExp(/.*@processout.com/).test(user.details.email) ||
          hasFeature(oldFeatureFlags, "analytics-v2")) && (
          <SubButton
            key="data-explorer"
            location={location}
            route="data-explorer"
            routeRegex={/.*\/projects\/.*\/data-explorer\/(presets|chart-builder|editor)$/}
            name="Data explorer"
            richLabel={
              <span>
                <img
                  alt="Chart icon for data explorer"
                  src="//dashboard.processout.com/images/data-explorer.png"
                  style={{
                    height: "1.1em",
                    marginRight: ".5em",
                    opacity: new RegExp(
                      /.*\/projects\/.*\/data-explorer\/(presets|chart-builder|editor)$/,
                    ).test(location.pathname)
                      ? 1
                      : 0.8,
                  }}
                />{" "}
                Data explorer
              </span>
            }
            to={`/projects/${params.project}/boards/data-explorer`}
          />
        )}
        {new RegExp(/.*@processout.com$/).test(user.details.email) && [
          <SubButton
            key="sales-board"
            location={location}
            route="board_default-sales"
            routeRegex={/.*\/projects\/.*\/boards\/board_default-sales/}
            name={<span>Sales overview</span>}
            to={`/projects/${params.project}/boards/board_default-sales`}
          />,
          <SubButton
            key="payment-perf-board"
            location={location}
            route="board_default-providers"
            routeRegex={/.*\/projects\/.*\/boards\/board_default-providers/}
            name={<span>Payment performance</span>}
            to={`/projects/${params.project}/boards/board_default-providers`}
          />,
        ]}
        {hasFeature(oldFeatureFlags, "analytics-v2") && (
          <SubButton
            key="board-creator"
            location={location}
            route="new"
            routeRegex={/.*\/projects\/.*\/boards\/new/}
            name="Create new board"
            richLabel={<span>+ Create new board</span>}
            to={`/projects/${params.project}/boards/new`}
          />
        )}
        {boards.boards && boards.boards.length > 0 && (
          <>
            <li className="menu-separator" />
            {boards.boards.map(board => (
              <SubButton
                key={board.id}
                location={location}
                route={board.id}
                routeRegex={new RegExp(`.*\projects\/.*\/boards\/${board.id}.*`)}
                name={board.name}
                to={`/projects/${params.project}/boards/${board.id}?${queryString}`}
              />
            ))}
          </>
        )}
      </MenuButton>
    )

    let settings
    let applePay
    const isNewDashSettingsEnabled = launchDarkly.flags["new-dash-settings-page"]
    const isNewDashApplePayEnabled = launchDarkly.flags["new-dash-apple-pay"]

    if (!sandbox) {
      settings = (
        <MenuButton
          location={location}
          route="settings"
          name="Settings"
          to={
            isNewDashSettingsEnabled
              ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/settings/project`
              : `/projects/${params.project}/settings/general`
          }
          externalLink={isNewDashSettingsEnabled}
          launchDarkly={launchDarkly}
        >
          <SubButton
            location={location}
            route="general"
            name="General"
            to={`/projects/${params.project}/settings/general`}
          />
          <SubButton
            location={location}
            route="team"
            name="Team"
            to={`/projects/${params.project}/settings/team`}
          />
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "subscriptions-activated") && (
              <SubButton
                location={location}
                route="dunning"
                name="Dunning"
                to={`/projects/${params.project}/settings/dunning`}
              />
            )}
        </MenuButton>
      )
      const hasApplePay = !!currentProject.project?.applepay_settings?.merchant_id
      const redirectToApplePayForm = "/add?source=myConnections&gateway=applepay"
      const redirection = hasApplePay ? "?redirectedFrom=oldApplePay" : redirectToApplePayForm
      applePay = !isNewDashApplePayEnabled ? (
        <MenuButton
          location={location}
          route="apple-pay"
          name="Apple Pay"
          to={`/projects/${params.project}/settings/apple-pay`}
        />
      ) : (
        <MenuButton
          location={location}
          externalLink
          route="apple-pay"
          name="Apple Pay"
          to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers${redirection}`}
        />
      )
    }

    const isTelescopeV2Enabled = launchDarkly.flags["dashboard-telescope-v2"]
    const telescope = isTelescopeV2Enabled ? (
      <>
        <MenuButton
          location={location}
          route="authorization"
          name="Authorization"
          to={`/projects/${params.project}/authorization`}
        />
        <MenuButton
          location={location}
          route="domains-secure"
          name="3DS Success"
          to={`/projects/${params.project}/domains-secure`}
        />
        <MenuButton
          location={location}
          route="capture-voids"
          name="Capture & voids"
          to={`/projects/${params.project}/capture-voids`}
        />
        <MenuButton
          location={location}
          route="refunds"
          name="Refunds"
          to={`/projects/${params.project}/refunds`}
        />
        <MenuButton
          location={location}
          route="fees"
          name="Fees"
          to={`/projects/${params.project}/fees`}
        />
        {/* action taken from this task: https://checkout.atlassian.net/browse/POF-781 */}
        {/* <MenuButton
          location={location}
          route="chargebacks"
          name="Chargebacks"
          to={`/projects/${params.project}/chargebacks`}
        /> */}
        <MenuButton
          location={location}
          route="anomaly-detection"
          name="Anomaly detection"
          to={`/projects/${params.project}/anomaly-detection`}
        />
      </>
    ) : (
      <MenuButton
        location={location}
        route="telescope"
        name="Telescope"
        to={`/projects/${params.project}/telescope`}
      />
    )
    // Feature flags might not be ready when Sidemenu is renderred (It actually re-renders multiple times).
    // But it is has less visual impact when a small menu is inserted.
    const routing = launchDarkly.flags["new-dash-routing-page"] ? (
      <>
        <MenuButton
          location={location}
          route="router"
          name="Routing"
          to={`/projects/${params.project}/router`}
        />
        <MenuButton
          isNew
          location={location}
          name="Routing"
          to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/routing`}
          externalLink
        />
      </>
    ) : (
      <>
        <MenuButton
          location={location}
          route="router"
          name="Smart routing"
          to={`/projects/${params.project}/router`}
        />
        {launchDarkly.flags["dashboard-routing-rules-v2"] && (
          <MenuButton
            beta
            location={location}
            route="router-beta"
            name="Smart routing"
            to={`/projects/${params.project}/router-beta`}
          />
        )}
      </>
    )

    const transactions = launchDarkly.flags["new-dash-transactions-page"] ? (
      <MenuButton
        location={location}
        name="Transactions"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/transactions`}
        externalLink
      />
    ) : (
      <MenuButton
        location={location}
        route="transactions"
        name="Transactions"
        to={`/projects/${params.project}/transactions`}
      />
    )

    const products = launchDarkly.flags["new-dash-payment-links-page"] ? (
      <MenuButton
        location={location}
        name="Products"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payment-links`}
        externalLink
      />
    ) : (
      <MenuButton
        location={location}
        route="products"
        name="Products"
        to={`/projects/${params.project}/products`}
      />
    )

    const exports = launchDarkly.flags["new-dash-exports-page"] ? (
      <MenuButton
        location={location}
        name="Exports"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/exports`}
        externalLink
        className="subButton"
      />
    ) : (
      <SubButton
        location={location}
        route="exports"
        name="Exports"
        routeRegex={new RegExp(`.*projects\/${params.project}\/reports\/exports.*`)}
        to={`/projects/${params.project}/reports/exports`}
      />
    )

    const payouts = launchDarkly.flags["new-dash-payouts-page"] ? (
      <MenuButton
        location={location}
        name="Payouts"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payouts`}
        externalLink
        className="subButton"
      />
    ) : (
      <SubButton
        location={location}
        route="Payouts"
        name="Payouts"
        routeRegex={new RegExp(`.*\projects\/${params.project}\/reports\/payouts.*`)}
        to={`/projects/${params.project}/reports/payouts`}
      />
    )

    const uploads = launchDarkly.flags["new-dash-uploads-page"] ? (
      <MenuButton
        location={location}
        name="Uploads"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/uploads`}
        externalLink
        className="subButton"
      />
    ) : (
      <SubButton
        location={location}
        route="Uploads"
        name="Uploads"
        routeRegex={new RegExp(`.*\projects\/${params.project}\/reports\/uploads.*`)}
        to={`/projects/${params.project}/reports/uploads`}
      />
    )

    let reportUrl = `/projects/${params.project}/reports/payouts`
    let isReportUrlExternal = false

    if (launchDarkly.flags["new-dash-payouts-page"]) {
      reportUrl = `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/payouts`
      isReportUrlExternal = true
    }

    const providers = launchDarkly.flags["new-dash-providers-page"] ? (
      <MenuButton
        location={location}
        externalLink
        name="Payment providers"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers`}
      />
    ) : (
      <MenuButton
        location={location}
        route="gateways"
        name="Payment providers"
        to={`/projects/${params.project}/gateways`}
      />
    )

    const fraudServices = launchDarkly.flags["new-dash-providers-page"] ? (
      <MenuButton
        location={location}
        externalLink
        name="Fraud services"
        to={`${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/providers`}
      />
    ) : (
      <MenuButton
        location={location}
        route="fraud-services"
        name="Fraud services"
        to={`/projects/${params.project}/fraud-services`}
      />
    )

    const reports = (
      <MenuButton
        location={location}
        route="reports"
        name="Reports"
        to={reportUrl}
        externalLink={isReportUrlExternal}
      >
        {uploads}
        {payouts}
        {exports}
      </MenuButton>
    )

    return (
      <nav className="main-side side-menu">
        {isTelescopeV2Enabled && (
          <ButtonGroup name="">
            <MenuButton
              location={location}
              route="overview"
              name="Overview"
              to={`/projects/${params.project}/overview`}
            />
            <div />
          </ButtonGroup>
        )}
        <ButtonGroup name="monitoring">
          {telescope}
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "alerts") && (
              <MenuButton
                location={location}
                route="alerting"
                name={
                  <div
                    style={{
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      Alerting
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        backgroundColor: "#D1C4E9",
                        color: "#512DA8",
                        padding: ".2em .5em .2em .5em",
                        marginLeft: "1em",
                        fontWeight: "bold",
                        fontSize: ".7em",
                        borderRadius: "4px",
                      }}
                    >
                      NEW
                    </div>
                  </div>
                }
                to={`/projects/${params.project}/alerting`}
              >
                <SubButton
                  location={location}
                  route="alerts"
                  name="Alerts"
                  to={`/projects/${params.project}/alerting/alerts`}
                />
                <SubButton
                  location={location}
                  route="events"
                  name="Events"
                  to={`/projects/${params.project}/alerting/events`}
                />
              </MenuButton>
            )}
          {analytics}
        </ButtonGroup>
        <ButtonGroup name="routing & connections">
          {providers}
          {currentProject.fetched && !currentProject.error && routing}
          {fraudServices}
        </ButtonGroup>
        <ButtonGroup name="Payments">
          {transactions}
          {products}

          {!launchDarkly.flags["new-dash-customers-page"] && (
            <MenuButton
              location={location}
              route="customers"
              name="Customers"
              to={`/projects/${params.project}/customers`}
            />
          )}
          {currentProject.fetched &&
            !currentProject.error &&
            hasFeature(oldFeatureFlags, "subscriptions-activated") && (
              <MenuButton
                location={location}
                route="recurring"
                name="Recurring billing"
                to={`/projects/${params.project}/recurring/subscriptions`}
              >
                <SubButton
                  location={location}
                  route="subscriptions"
                  name="Subscriptions"
                  to={`/projects/${params.project}/recurring/subscriptions`}
                />
                <SubButton
                  location={location}
                  route="plans"
                  name="Plans"
                  to={`/projects/${params.project}/recurring/plans`}
                />
                <SubButton
                  location={location}
                  route="coupons"
                  name="Coupons"
                  to={`/projects/${params.project}/recurring/coupons`}
                />
              </MenuButton>
            )}
          <MenuButton
            location={location}
            route="vault"
            name="Vault"
            to={`/projects/${params.project}/vault`}
          />
        </ButtonGroup>
        <ButtonGroup name="account">
          {reports}
          <MenuButton
            location={location}
            route="developer"
            name="Developer API"
            to={
              launchDarkly.flags["new-dash-api-setup-page"]
                ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/api-keys`
                : `/projects/${params.project}/developer/api-setup`
            }
            externalLink={launchDarkly.flags["new-dash-api-setup-page"]}
          >
            <SubButton
              location={location}
              route="api-setup"
              name="API Setup"
              to={
                launchDarkly.flags["new-dash-api-setup-page"]
                  ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/api-keys`
                  : `/projects/${params.project}/developer/api-setup`
              }
            />
            <SubButton
              location={location}
              route="events"
              name="Events"
              to={
                launchDarkly.flags["new-dash-events-page"]
                  ? `${process.env.DASHBOARD_NEXT_BASE_URL}/projects/${params.project}/events`
                  : `/projects/${params.project}/developer/events`
              }
            />
          </MenuButton>
          {settings}
          {applePay}
          <li
            style={{
              marginTop: "2em",
            }}
          >
            <SandboxSwitch />
          </li>
          <li>
            <UserTag />
          </li>
        </ButtonGroup>
      </nav>
    )
  }
}

class ButtonGroup extends React.Component {
  props: {
    name: string
    children: any
  }

  render() {
    const { children, name } = this.props
    if (children.filter(child => child !== null).length === 0) return null
    return (
      <div className="group">
        <div className="row">
          <div className="medium-12 columns">
            <div className="row">
              <div className="medium-12 columns">
                <span className="group-name">{name.toUpperCase()}</span>
              </div>
            </div>
            <div className="row">
              <div className="medium-12 columns">
                <ul>{children}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(store => ({
  permissions: store.permissions,
  boards: store.analytics_v2.boards,
  currentProject: store.currentProject,
  analyticsParams: store.analytics.params,
  moderators: store.moderators,
  user: store.user,
  launchDarkly: store.launchDarkly,
}))(withRouter(SideMenu))
